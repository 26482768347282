<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card class="min-vh-100">
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
        <b-row>
					<ResponseAlert ref="response" />
          <b-col>
            <b-form-group>
							<div class="col-sm-12 no-padding mt-3">
								<label class="radio-inline no-padding">
									<input type="radio" name="type" v-model="models.type" value="image">
									<span>Image</span>
								</label>
								<label class="radio-inline">
									<input type="radio" name="type" v-model="models.type" value="embed">
									<span>Embed Youtube</span>
								</label>
							</div>
						</b-form-group>
            <div class="my-4" v-if="type_ads === 'embed'">
              <div v-if="vars.videoSrc" class="d-flex justify-content-center">
                <iframe ref="frame" width="100%" height="440" :src="vars.videoSrc">
                </iframe>
              </div>
              <div>
                <b-button v-show="!vars.videoUrlActive" block class="btn btn-primary text-white" @click="vars.videoUrlActive = !vars.videoUrlActive">
                  Add Video URL
                </b-button>
                <div v-show="vars.videoUrlActive" ref="url_yt">
                  <b-input-group class="mt-3">
                    <b-form-input placeholder="Insert video url..." v-model="models.path" v-validate="'required'" data-vv-name="url"></b-form-input>
                    <b-input-group-append>
                      <b-button @click.prevent="getSrcVideo" variant="primary">Show Preview</b-button>
                      <b-button variantawait ="outline-danger" @click="vars.videoUrlActive = !vars.videoUrlActive; vars.videoSrc = null; models.path = null;">Cancel</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
								<div v-if="errors.has('url')">
									<p class="text-danger mt-1 text-center">{{ errors.first('url') }}</p>
								</div>
              </div>
            </div>
            <div class="my-4" v-else>
              <b-img :src="getUrlOfFile(models.path)" fluid class="d-block m-auto" style="max-height: 300px"/>
              <b-button class="btn-upload btn-block mt-3" @click="$refs.filemanager.show('advertisement')">
                  <i class="fa fa-upload"></i> Upload Image
              </b-button>
              <b-form-input v-if="!vars.onLoadData" hidden v-model="models.path" v-validate="'required'" data-vv-name="image"></b-form-input>

							<div v-if="errors.has('image')">
								<p class="text-danger mt-1 text-center">{{ errors.first('image') }}</p>
							</div>
            </div>
          </b-col>
        </b-row>
				<b-row>
					<b-col md="5">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('name')}" label="Name">
							<b-form-input v-model="models.name" v-validate="'required'" data-vv-name="name">
							</b-form-input>
							<div v-if="errors.has('name')">
								<p class="text-danger">{{ errors.first('name') }}</p>
							</div>
						</b-form-group>
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('start date')}" label="Start Date">
							<b-form-input type="datetime-local" v-model="models.start_date" v-validate="'required'" data-vv-name="start date" :min="vars.start_min">
							</b-form-input>
							<div v-if="errors.has('start date')">
								<p class="text-danger">{{ errors.first('start date') }}</p>
							</div>
						</b-form-group>
            <b-form-group v-bind:class="{ 'is-invalid': errors.has('link')}" label="Link">
							<b-form-input v-model="models.link" v-validate="'required|url'" data-vv-name="link">
							</b-form-input>
							<div v-if="errors.has('link')">
								<p class="text-danger">{{ errors.first('link') }}</p>
							</div>
						</b-form-group>
					</b-col>
					<b-col md="5">
            <b-form-group v-bind:class="{ 'is-invalid': errors.has('end date')}" label="End Date">
							<b-form-input type="datetime-local" v-model="models.end_date" v-validate="'required'" data-vv-name="end date" :min="vars.end_min">
							</b-form-input>
							<div v-if="errors.has('end date')">
								<p class="text-danger">{{ errors.first('end date') }}</p>
							</div>
						</b-form-group>
						<b-form-group>
							<div class="col-sm-12 no-padding mt-3 mb-1">
								<label class="radio-inline no-padding">
									<input type="radio" v-model="models.status" value="publish">
									<span>Publish</span>
								</label>
								<label class="radio-inline">
									<input type="radio" v-model="models.status" value="draft">
									<span>Draft</span>
								</label>
							</div>
						</b-form-group>
            <b-form-group v-bind:class="{ 'is-invalid': errors.has('timer')}" label="Timer" class="mt-5">
							<b-form-input v-model="models.timer" v-validate="'required'" type="number" data-vv-name="timer">
							</b-form-input>
							<div v-if="errors.has('timer')">
								<p class="text-danger">{{ errors.first('timer') }}</p>
							</div>
						</b-form-group>
					</b-col>
				</b-row>
        <template #footer>
					<b-button type="submit" variant="primary" class="btn-min-width rounded float-left">
						Save
					</b-button>
					<b-button type="button" variant="light" class="btn-min-width rounded float-left" @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
        <Filemanager ref="filemanager" name="categories" @selectMedia="selectMedia"/>

			</b-card>
		</div>
	</form>
</template>
<script>
	import moment from 'moment'
	import api from '@/utils/api.js'
	import getYoutubeId from 'get-youtube-id'
	import ResponseAlert from '@/components/response_alert';
	import Filemanager from '@/components/file_manager';

  import {
		getUrlOfFile
	} from '@/utils/helpers.js';

	export default {
		name: 'ListAdvertisement',
		components: {
			ResponseAlert,
      Filemanager
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					name: "",
          type: "image",
          link: "",
          path: "",
          start_date: "",
          end_date: "",
          timer: 0,
          status: "publish"
				},
        vars: {
					videoSrc: null,
					videoUrlActive: false,
					onLoadData: false,

					start_min: moment().format('YYYY-MM-DDTHH:mm'),
					end_min: moment().format('YYYY-MM-DDTHH:mm')
				},
				moment
			}
		},
		created() {
			let _ = this
			_.getById();
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.vars.onLoadData = true
					_.$axios.get(`${api.advertisement}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.name = data.name,
							_.models.type = data.type,
							_.models.link = data.link,
							_.models.path = data.path,
							_.models.start_date = moment(data.start_date).format('YYYY-MM-DDTHH:mm'),
							_.models.end_date = moment(data.end_date).format('YYYY-MM-DDTHH:mm'),
              _.models.timer = data.timer,
							_.models.status = data.status

							_.vars.start_min = moment(data.start_date).format('YYYY-MM-DDTHH:mm')
							_.vars.end_min = moment(data.start_date).format('YYYY-MM-DDTHH:mm')

							if(data.type === 'embed') {
								this.getSrcVideo()
							}

							this.$nextTick(() => {
								_.vars.onLoadData = false
							});
						}).catch(() => {
							_.vars.onLoadData = false
							_.$router.push({
								name: 'page404'
							})
						});
				}
			},

      getUrlOfFile(file) {
				return getUrlOfFile(file, true);
			},

      getSrcVideo() {
				const fileUrl = this.models.path
				if(fileUrl.toLowerCase().includes('youtube.com')) {
					this.vars.videoSrc = 'https://www.youtube.com/embed/' + getYoutubeId(this.models.path)
				} else {
					this.vars.videoSrc = this.models.path
				}
			},

      selectMedia(field, media) {
				const _ = this;
				const imageExtension = ['jpg', 'jpeg', 'png']
				const isExtensionMatch = extensions => extensions.indexOf(media.file.substring(media.file.lastIndexOf(".") + 1)) > -1

				if(field === 'image' && !isExtensionMatch(imageExtension)){
					return alert('Only accept image file')
				}
				_.models.path = media.file;
			},
			
			save() {
				const _ = this;
				_.$validator.validate().then(valid => {
					if (valid) {
						let request = "";
						let message = "create"

						if (!_.$route.params.id) {
							request = _.$axios.post(api.advertisement, _.models);
						} else {
							message = "update"
							request = _.$axios.put(`${api.advertisement}/${_.$route.params.id}`, _.models);
						}
						request
							.then(() => {
								this.$showToast('Success', `Success ${message} advertisement`)
								_.$router.push({
									name: _.config.uri
								})
							})
							.catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					} else {
						window.scrollTo(0, 0);
					}
				});
			}
		},
    computed: {
      type_ads() {
        return this.models.type
      },
			start_date() {
				return this.models.start_date
			},
    },
    watch: {
      type_ads() {
				if(!this.vars.onLoadData) {
					this.models.path = ""
					this.vars.videoSrc = ""
					this.vars.videoUrlActive = false
				}
      },
			start_date() {
				if(!this.vars.onLoadData) {
					this.vars.end_min = moment(this.models.start_date).format('YYYY-MM-DDTHH:mm')
					if (moment(this.models.start_date).isAfter(this.models.end_date)) {
						this.models.end_date = moment(this.models.start_date).format('YYYY-MM-DDTHH:mm')
					} 
				}
			},
    }
	}
</script>